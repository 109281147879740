import React, { FC } from "react";
import styled from "styled-components";
import {
  QuestionsAnswersWithBackend,
  WithLanguageFiltering,
} from "./decorators";
import Expander from "../../molecules/expander";
import { compareByPresentationOrder } from "../../../utils/misc";
import { Answer, Question } from "./types";
import { Answers } from "./answers";
import { LanguagesWithBackend } from "../languages/decorators";

export type QuestionsAnswersProps = {
  questions: Array<Question>;
  answers: Array<Answer>;
  ariaLabel?: string;
};

const QuestionsContainer = styled.section`
  display: flex;
  flex-flow: column wrap;
  align-items: flex-start;
  height: 100%;
  width: 100%;
`;

export const QuestionsAnswers: FC<QuestionsAnswersProps> = ({
  questions,
  answers,
  ariaLabel,
  ...rest
}) => {
  return (
    <QuestionsContainer aria-label={ariaLabel || "Collection of questions"} {...rest}>
      {questions.sort(compareByPresentationOrder).map((q) => {
        const { id, question } = q;
        const questionAnswers = answers.filter(
          (c) => c.relatedQuestionId === id
        );
        return (
          <Expander
            key={id}
            text={question}
            content={<Answers question={q} questionAnswers={questionAnswers} />}
          />
        );
      })}
    </QuestionsContainer>
  );
};

type WithBackendProps = Omit<QuestionsAnswersProps, "questions" | "answers">;

const WithBackend: FC<WithBackendProps> = (props) => {
  return (
    <QuestionsAnswersWithBackend
      render={(data) => <QuestionsAnswers {...data} {...props} />}
    />
  );
};

const WithBackendAndLanguageFiltering: FC<WithBackendProps> = (props) => {
  return (
    <LanguagesWithBackend
      render={({ languages }) => (
        <WithLanguageFiltering languages={languages}>
          <WithBackend {...props} />
        </WithLanguageFiltering>
      )}
    />
  );
};

export default WithBackendAndLanguageFiltering;
