import React, { FC } from "react";
import styled from "styled-components";
import { TranslationKey } from "../../../translations/languages";
import { getTranslation } from "../../../translations/questionsAnswers";
import { useLanguageState } from "../../../utils/contexts/languageContext";
import QuestionsAnswers from "../questionsAnswers";
import { mediaBreakpoints, rhythm } from "../../../utils/typography";

const { md, lg, xl, xxl } = mediaBreakpoints;

type SendAndExploreQuestionsProps = {};

const Container = styled.div`
  @media only screen and (min-width: ${lg}) {
    & > :first-child {
      padding: 0 1rem;
    }
  }
`;

export const SendAndExploreQuestions: FC<SendAndExploreQuestionsProps> = ({
  ...rest
}) => {
  const { languageCode: currentLanguageCode } = useLanguageState();
  const lang = currentLanguageCode as TranslationKey;

  const header = getTranslation(lang, "questionsAndAnswers");

  return (
    <Container {...rest}>
      <h2>{header}</h2>
      <QuestionsAnswers ariaLabel={header}/>
    </Container>
  );
};
