import React, { useEffect, useState, useCallback } from "react";
import { PageProps, navigate } from "gatsby";
import Topics from "../components/organisms/topics";
import Footer from "../components/molecules/footer";
import NavigationBar from "../components/molecules/navigationBar";
import { useLanguageState } from "../utils/contexts/languageContext";
import Header from "../components/atoms/header";
import { getTranslation } from "../translations/header";
import {
  Main,
  PageContainer,
  pageContentStyles,
} from "../components/atoms/pageContent";
import { TranslationKey } from "../translations/languages";
import SubTopics from "../components/organisms/subTopics";
import { colorThemes } from "../utils/typography";
import styled from "styled-components";
import { getHome } from "../translations/breadcrumb";
import { useBreadcrumb } from "gatsby-plugin-breadcrumb";
import LanguagesMenu from "../components/organisms/languages/languagesMenu";
import { SendAndExploreQuestions } from "../components/organisms/sendAndExploreQuestions/index";
import { mediaBreakpoints } from "../utils/typography";
import SEO from "../components/atoms/seo";
import {LinkToContent, contentId} from "../components/atoms/contentlink";
import useIsClient from "../utils/hooks/isClient"

const { lg } = mediaBreakpoints;
const { subTopics: subTopicsTheme } = colorThemes;

const SubTopicsContainer = styled.div`
  ${pageContentStyles}
  margin: 0rem auto;
`;

const StyledSendAndExploreQuestions = styled(SendAndExploreQuestions)`
  ${pageContentStyles}
`;

const TopicsPage = ({ location }: PageProps) => {
  const { isClient, key } = useIsClient();
  const { languageCode } = useLanguageState();
  const [{ header, subTitle }, setHeader] = useState({
    header: "",
    subTitle: "",
  });

  const homeBread = getHome(languageCode);
  useBreadcrumb({
    location,
    homeBread,
  });

  useEffect(() => {
    const header = getTranslation(languageCode as TranslationKey, "header");
    const subTitle = getTranslation(languageCode as TranslationKey, "subTitle");
    setHeader({ header, subTitle });
  }, [setHeader, languageCode]);

  // Navigate to language selection if no language is set.
  useEffect(() => {
    if (!languageCode) {
      navigate("/language/");
    }
  }, [languageCode, navigate]);

  if ( !isClient ) return null;
  return (
    <PageContainer>
      <SEO />
      <div key={key}>
        <LinkToContent languageCode={languageCode as TranslationKey}/>
        <NavigationBar>
          <LanguagesMenu />
        </NavigationBar>
        <Header heading={header} subtitle={subTitle} />
      </div>
      <Main id={contentId}>
        <Topics />
      </Main>
      <div>
        <div style={{ background: subTopicsTheme.primary }}>
          <SubTopicsContainer>
            <SubTopics />
          </SubTopicsContainer>
        </div>
        <StyledSendAndExploreQuestions />
        <Footer />
      </div>
    </PageContainer>
  );
};

export default TopicsPage;
