import { graphql, StaticQuery } from "gatsby";
import React, { FC } from "react";
import { SubTopic, SubTopicsProps } from ".";
import { useLanguageState } from "../../../utils/contexts/languageContext";
import { isStatusAllowed } from "../../../utils/misc";
import { RenderCallback } from "../../../utils/types/misc";

export type SubTopicsWithBackendProps = {
  render: RenderCallback<SubTopicsProps>;
};

type DirectusSubTopic = {
  id: string;
  status: string;
  presentation_order: number;
  sub_topic_page: {
    id: string;
  };
  translations: [
    {
      sub_topic_header: string;
      languages_code: {
        code: string;
      };
    }
  ];
};

type DirectusData = {
  directus: { sub_topics: Array<DirectusSubTopic> };
};

export const SubTopicsWithBackend: FC<SubTopicsWithBackendProps> = ({
  render,
}) => {
  return (
    <StaticQuery
      query={graphql`
        {
          directus {
            sub_topics(filter: { status: { _in: ["published", "draft"] } }) {
              id
              status
              presentation_order
              translations {
                languages_code {
                  code
                }
                sub_topic_header
              }
              sub_topic_page {
                id
              }
            }
          }
        }
      `}
      render={({ directus: { sub_topics } }: DirectusData) => {
        const { languageCode: currentLanguage } = useLanguageState();
        const topics = sub_topics
          .filter((c) => isStatusAllowed(c.status))
          .map(
            ({
              id,
              translations,
              sub_topic_page: { id: pageId },
              presentation_order,
            }) => {
              const topicTranslation = translations.find(
                (t: { languages_code: { code: string } }) =>
                  t.languages_code?.code == currentLanguage
              );

              if (!topicTranslation) {
                return null;
              }

              const topic: SubTopic = {
                id: id,
                text: topicTranslation?.sub_topic_header,
                languageCode: topicTranslation?.languages_code?.code,
                pageId,
                presentationOrder: presentation_order,
              };
              return topic;
            }
          )
          .filter((c) => !!c);

        return render({ topics: topics as SubTopic[] });
      }}
    />
  );
};
