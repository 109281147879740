import React, { FC, ReactNode, useState } from "react";
import styled from "styled-components";
import { colorThemes, options, rhythm, scale } from "../../utils/typography";
import ActionButton from "./actionbutton";

export type ExpanderProps = {
  text: string;
  isInitialOpen?: boolean;
  content: ReactNode;
};

const {
  separator: { primary: separatorPrimary },
  expander: { primary: expanderPrimary },
} = colorThemes;
const { bodyColor, bodyFontFamily, bodyWeight } = options;

const ContentContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  background-color: ${expanderPrimary};
  width: 100%;
  padding: ${rhythm(0.25)} ${rhythm(1)};
  & > :first-child {
    justify-self: flex-start;
    margin-right: auto;
  }
  border-bottom: 3px solid ${separatorPrimary};
`;

const ExpanderButton = styled(ActionButton)`
  width: 100%;
  border-width: 0px;
  border-bottom: 3px solid ${separatorPrimary};
  border-radius: 0px;
  min-height: ${rhythm(4)};

  & .content_right {
    visibility: visible;
  }

  &&:hover {
    border-top: 0px;
    border-right: 0px;
    border-left: 0px;
    box-shadow: unset;
  }

  &:focus {
    border-top: 0px;
    border-right: 0px;
    box-shadow: unset;
  }

  &:not([disabled]),
  &:not([aria-disabled="true"]) {
    &:active,
    &[data-active="true"] {
      border-top: 0px;
      border-right: 0px;
      box-shadow: unset;
    }
  }
`;

const { fontSize: expanderFontSize, lineHeight: expanderLineHeight } = scale(
  0 / 5
);
const ExpanderText = styled.span`
  color: ${bodyColor};
  font-family: ${bodyFontFamily?.join(",")};
  font-weight: ${bodyWeight};
  line-height: ${expanderLineHeight};
  font-size: ${expanderFontSize};
`;

const ExpanderContent: FC = ({ children }) => {
  return <ContentContainer>{children}</ContentContainer>;
};

const Expander: FC<ExpanderProps> = ({ text, isInitialOpen, content }) => {
  const [isOpen, setIsOpen] = useState(isInitialOpen ?? false);
  return (
    <div style={{ width: "100%" }}>
      <ExpanderButton
        aria-label={text}
        action="expand"
        contentLeft={<ExpanderText>{text}</ExpanderText>}
        onClick={() => setIsOpen(!isOpen)}
        isSelected={isOpen}
      />
      {isOpen && <ExpanderContent>{content}</ExpanderContent>}
    </div>
  );
};

export default Expander;
