import React, { FC } from "react";
import { navigate } from "gatsby";
import styled from "styled-components";
import {
  colorThemes,
  rhythm,
  mediaBreakpoints,
} from "../../../utils/typography";
import { SubTopicsWithBackend } from "./decorators";
import TextIconButton, {
  TextIconButtonProps,
} from "../../atoms/button/texticonbutton";
import Icon from "../../atoms/icon";
import { getTranslation } from "../../../translations/subTopics";
import { useLanguageState } from "../../../utils/contexts/languageContext";
import { TranslationKey } from "../../../translations/languages";
import Link from "../../atoms/link";
import { compareByPresentationOrder } from "../../../utils/misc";
import { headerTextStyles } from "../../atoms/pageContent";
import { rootPagePath } from "../../../utils/path";

const { subTopics: subTopicsTheme } = colorThemes;
const { md, lg } = mediaBreakpoints;

export type SubTopic = {
  id: string;
  text: string;
  languageCode: string;
  pageId: string;
  presentationOrder: number;
};

export type SubTopicsProps = {
  topics: Array<SubTopic>;
};

const Container = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: stretch;
  align-items: stretch;
  text-align: center;
  width: 100%;
  background: ${subTopicsTheme.primary};
  & > * {
    margin: ${rhythm(1)} 0;
  }
  & > :first-child {
    text-align: center;
    width: 100%;
    margin-bottom: ${rhythm(0.5)};
    @media only screen and (min-width: ${md}) {
      text-align: start;
    }
  }
  @media only screen and (min-width: ${md}) {
    align-items: flex-start;
  }
  @media only screen and (min-width: ${lg}) {
    flex-flow: row wrap;
    justify-content: space-evenly;
    & > * {
      margin: ${rhythm(1)} ${rhythm(1)};
    }
  }
`;

const Heading = styled.h2`
  ${headerTextStyles}
  width: 100%;
  margin-left: 0px;
`;

const NavigationButton = styled((props: TextIconButtonProps) => (
  <TextIconButton {...props} />
))`
  width: 100%;
  & button {
    width: 100%;
  }
  &&& {
    &:hover,
    &:active,
    &[data-active="true"] {
      background-color: ${subTopicsTheme.secondary};
      color: ${subTopicsTheme.primary};
    }
  }

  @media only screen and (min-width: ${md}) {
    width: 15rem;
  }
`;

export const SubTopics: FC<SubTopicsProps> = ({ topics }) => {
  const { languageCode: currentLanguageCode } = useLanguageState();
  const header = getTranslation(
    currentLanguageCode as TranslationKey,
    "header"
  );
  return (
    <Container>
      <Heading>{header}</Heading>
      {topics
        .sort(compareByPresentationOrder)
        .map(({ languageCode, pageId, text }) => {
          return (
            <NavigationButton
              key={pageId}
              aria-label={text}
              contentLeft={text}
              contentRight={
                <Icon
                  icon="icon-arrow-right"
                  styles={{fontSize:"20px"}}
                />
              }
              onClick={() =>
                navigate(`/${rootPagePath}${languageCode}/${pageId}/`)
              }
            />
          );
        })}
    </Container>
  );
};

type WithBackendProps = Omit<SubTopicsProps, "topics">;

const WithBackend: FC<WithBackendProps> = () => (
  <SubTopicsWithBackend
    render={({ topics }) => <SubTopics topics={topics} />}
  />
);

export default WithBackend;
