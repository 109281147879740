import React, { FC } from "react";
import { navigate } from "gatsby";
import { GatsbyImage, getImage, IGatsbyImageData } from "gatsby-plugin-image";
import { TopicsWithBackend } from "./decorators";
import styled from "styled-components";
import { Button } from "reakit";
import {
  colorThemes,
  mediaBreakpoints,
  rhythm,
  scale,
} from "../../../utils/typography";
import { compareByPresentationOrder } from "../../../utils/misc";
import { TranslationKey } from "../../../translations/languages";
import { getTranslation } from "../../../translations/topics";
import { useLanguageState } from "../../../utils/contexts/languageContext";
import { bodyTextStyles, headerTextStyles } from "../../atoms/pageContent";
import { rootPagePath } from "../../../utils/path";
import { darken } from "polished";

const { button: buttonTheme, topics: topicsTheme } = colorThemes;
const { md, lg } = mediaBreakpoints;

export type Topic = {
  id: string;
  text: string;
  languageCode: string;
  pageId: string;
  topicImage?: string;
  presentationOrder: number;
};

export type TopicImage = {
  childImageSharp: any;
  name: string;
  ext: string;
  fullName: string;
};

export type TopicsProps = {
  topics: Array<Topic>;
  viewpointTopics: Array<Topic>;
  images: Array<TopicImage>;
};

const Container = styled.div`
  display: flex;
  flex-flow: column nowrap;
  & > :first-child {
    text-align: center;
    width: 100%;
    margin-bottom: ${rhythm(1)};
    @media only screen and (min-width: ${md}) {
      text-align: start;
    }
  }
`;

const TopicsContainer = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr;
  row-gap: ${rhythm(1)};
  & a {
    text-decoration: none;
  }

  @media only screen and (min-width: ${lg}) {
    row-gap: ${rhythm(4)};
    grid-template-columns: repeat(auto-fill, minmax(min(20rem, 100%), 1fr));
  }
`;

const TopicContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  width: auto;
  & > :not(:first-child) {
    margin-left: 1rem;
  }
  & > .icon {
    visibility: hidden;
  }
  &:hover {
    background-color: ${darken(0.01, topicsTheme.primary)};
  }

  &:active,
  &[data-active="true"] &:focus,
  &:focus-visible {
    background-color: ${darken(0.04, topicsTheme.primary)};
  }
  @media only screen and (min-width: ${lg}) {
    & > :not(:first-child) {
      margin-left: 0rem;
    }
    flex-flow: column nowrap;
    & > * {
      margin-top: 0.5rem;
    }
    & > .icon {
      visibility: visible;
    }
  }
`;

const TopicButton = styled(Button)`
  background-color: transparent;
  border-color: transparent;
  border-style: solid;
  cursor: pointer;

  &:hover {
    background-color: ${darken(0.01, topicsTheme.primary)};
  }

  &:active,
  &[data-active="true"] &:focus,
  &:focus-visible {
    background-color: ${darken(0.04, topicsTheme.primary)};
  }

  @media only screen and (min-width: ${lg}) {
    width: ${rhythm(10)};
  }
`;

const Heading = styled.h2`
  width: 100%;
  ${headerTextStyles}
  margin-left: 0px;
`;

const { fontSize: topicFontSize, lineHeight: topicLineHeight } = scale(-1 / 5);
const TopicText = styled.span`
  ${bodyTextStyles}
  line-height: ${topicLineHeight};
  font-size: ${topicFontSize};
`;

const StyledGatsbyImage = styled(GatsbyImage)`
  width: 100px;
  @media only screen and (min-width: ${md}) {
    width: 200px;
  }
  @media only screen and (min-width: ${lg}) {
    width: 200px;
  }
`;

type TopicProps = {
  topic: Topic;
  childImageSharp?: IGatsbyImageData;
};

const Topic: FC<TopicProps> = ({
  topic: { text, languageCode, pageId, topicImage },
  childImageSharp,
}) => {
  const image =
    childImageSharp !== undefined ? getImage(childImageSharp) : null;
  const handleOnclick = () => navigate(`/${rootPagePath}${languageCode}/${pageId}`);

  return (
    <TopicContainer onClick={handleOnclick}>
      {image && (
        <div aria-hidden="true">
          <StyledGatsbyImage
            image={image}
            alt={text}
          />
        </div>
      )}
      <TopicButton onClick={handleOnclick}>
        <TopicText>{text}</TopicText>
      </TopicButton>
    </TopicContainer>
  );
};

export const Topics: FC<TopicsProps> = ({
  topics,
  viewpointTopics,
  images,
  ...rest
}) => {
  const { languageCode: currentLanguageCode } = useLanguageState();
  const header = getTranslation(
    currentLanguageCode as TranslationKey,
    "header"
  );

  // Add default viewpoint selection to address to avoid problems with bread crumbs
  // TODO: This isn't failproof in case of future changes. Make the changes in database maybe?
  const viewpointsWithDefaultChosen = viewpointTopics.map(topic => ({ ...topic, pageId: `${topic.pageId}/?viewpoint=one` }))
  const allTopics = [...viewpointsWithDefaultChosen, ...topics].sort(
    compareByPresentationOrder
  );

  return (
    <Container {...rest}>
      <Heading>{header}</Heading>
      <TopicsContainer>
        {allTopics.map((c) => (
          <Topic
            key={c.id}
            topic={c}
            childImageSharp={
              images.find((i) => i.fullName === c.topicImage)?.childImageSharp
            }
          />
        ))}
      </TopicsContainer>
    </Container>
  );
};

type WithBackendProps = Omit<
  TopicsProps,
  "topics" | "viewpointTopics" | "images"
>;

const WithBackend: FC<WithBackendProps> = (props) => {
  return (
    <TopicsWithBackend
      render={({ topics, viewpointTopics, images }) => (
        <Topics
          topics={topics}
          viewpointTopics={viewpointTopics}
          images={images}
          {...props}
        />
      )}
    />
  );
};

export default WithBackend;
