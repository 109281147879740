import React from "react";
import styled from "styled-components";
import { scale, options } from "../../utils/typography";

const { bodyColor, bodyFontFamily, boldWeight } = options;

const { fontSize: expanderFontSize, lineHeight: expanderLineHeight } = scale(
  0 / 5
);

const Label = styled.label`
  color: ${bodyColor};
  font-family: ${bodyFontFamily?.join(",")};
  font-weight: ${boldWeight};
  line-height: ${expanderLineHeight};
  font-size: ${expanderFontSize};
`;

export default Label;
