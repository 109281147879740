import React, { FC } from "react";
import { CountryKey } from "../../../translations/countries";
import { WithCountriesDecorator } from "./decorators";
import { SelectCountryProps } from ".";
import SelectMenu, { SelectMenuProps } from "../../molecules/selectMenu";

export type SelectCountryMenuProps = SelectCountryProps &
  Pick<
    SelectMenuProps,
    | "initialPlaceHolder"
    | "selectedItemId"
    | "minwidth"
    | "menuAriaLabel"
    | "id"
  >;

export const SelectCountryMenu: FC<SelectCountryMenuProps> = ({
  countries,
  onCountrySelected,
  initialPlaceHolder,
  menuAriaLabel,
  minwidth,
  selectedItemId,
  id,
}) => {
  return (
    <SelectMenu
      id={id}
      menuAriaLabel={menuAriaLabel}
      menuItems={countries.map(({ key, label }) => ({
        id: key,
        content: label,
      }))}
      onMenuItemSelected={(id) => onCountrySelected(id as CountryKey)}
      initialPlaceHolder={initialPlaceHolder}
      minwidth={minwidth}
      selectedItemId={selectedItemId}
    />
  );
};

type WithCountriesProps = Omit<SelectCountryMenuProps, "countries">;
const WithCountries: FC<WithCountriesProps> = (props) => (
  <WithCountriesDecorator
    render={({ countries }) => (
      <SelectCountryMenu countries={countries} {...props} />
    )}
  />
);
export default WithCountries;
