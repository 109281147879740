import { graphql, StaticQuery } from "gatsby";
import { IGatsbyImageData } from "gatsby-plugin-image";
import React, { FC, ReactElement } from "react";
import { Topic, TopicImage, TopicsProps } from ".";
import { useLanguageState } from "../../../utils/contexts/languageContext";
import { isStatusAllowed } from "../../../utils/misc";
import { RenderCallback } from "../../../utils/types/misc";

export type TopicsWithBackendProps = {
  render: RenderCallback<
    Pick<TopicsProps, "topics" | "viewpointTopics" | "images">
  >;
};

type DirectusTopic = {
  id: string;
  status: string;
  presentation_order: number;
  topic_page: {
    id: string;
  };
  translations: [
    {
      topic_header: string;
      languages_code: {
        code: string;
      };
    }
  ];
  topic_picture: {
    filename_download: string;
    filename_disk: string;
  };
};

type DirectusViewpointTopic = {
  id: string;
  status: string;
  presentation_order: number;
  translations: [
    {
      languages_code: {
        code: string;
      };
      viewpoint_topic_header: string;
    }
  ];
  viewpoint_topic_picture: {
    filename_disk: string;
    filename_download: string;
  };
};

type DirectusFile = {
  remoteImage: {
    childImageSharp: IGatsbyImageData;
    name: string;
    ext: string; // .jpg
  };
  filename_disk: string;
};

type DirectusData = {
  directus: {
    topics: Array<DirectusTopic>;
    viewpoint_topics: Array<DirectusViewpointTopic>;
  };
  allDirectusFile: { nodes: Array<DirectusFile> };
};

export const TopicsWithBackend: FC<TopicsWithBackendProps> = ({
  render,
}: TopicsWithBackendProps): ReactElement<any, any> | null => {
  return (
    <StaticQuery
      query={graphql`
        {
          directus {
            topics(
              limit: -1
              filter: { status: { _in: ["published", "draft"] } }
            ) {
              id
              status
              presentation_order
              topic_page {
                id
              }
              translations {
                topic_header
                languages_code {
                  code
                }
              }
              topic_picture {
                filename_download
                filename_disk
              }
            }
            viewpoint_topics(
              limit: -1
              filter: { status: { _in: ["published", "draft"] } }
            ) {
              id
              status
              presentation_order
              translations {
                languages_code {
                  code
                }
                viewpoint_topic_header
              }
              viewpoint_topic_picture {
                filename_disk
                filename_download
              }
            }
          }
          allDirectusFile(
            filter: {
              remoteImage: { childImageSharp: { id: { regex: "/.+/" } } }
            }
          ) {
            nodes {
              remoteImage {
                childImageSharp {
                  gatsbyImageData(layout: CONSTRAINED)
                  id
                }
                name
                ext
              }
              filename_disk
            }
          }
        }
      `}
      render={({
        directus: { topics, viewpoint_topics },
        allDirectusFile: { nodes: fileNodes },
      }: DirectusData) => {
        const { languageCode: currentLanguage } = useLanguageState();

        // Collection of images that topics require so that only necessary pictures can be given to render component.
        const topicImageMap = {};

        const mappedTopics: Array<Topic> = topics
          .filter((c) => isStatusAllowed(c.status))
          .map(
            ({
              id,
              translations,
              topic_page: { id: pageId },
              topic_picture,
              presentation_order,
            }) => {
              const topicTranslation = translations.find(
                (t: { languages_code: { code: string } }) =>
                  t.languages_code?.code == currentLanguage
              );

              if (!topicTranslation) {
                return null;
              }

              if (topic_picture?.filename_disk) {
                topicImageMap[topic_picture.filename_disk] = true;
              }

              const topic: Topic = {
                id: id,
                text: topicTranslation?.topic_header,
                languageCode: topicTranslation?.languages_code?.code,
                pageId,
                topicImage: topic_picture?.filename_download,
                presentationOrder: presentation_order,
              };
              return topic;
            }
          )
          .filter((c) => !!c) as Topic[];

        const mappedViewpointTopics: Array<Topic> = viewpoint_topics
          .filter((c) => isStatusAllowed(c.status))
          .map(
            ({
              id,
              translations,
              viewpoint_topic_picture,
              presentation_order,
            }) => {
              const translation = translations.find(
                (t: { languages_code: { code: string } }) =>
                  t.languages_code?.code == currentLanguage
              );

              if (!translation) {
                return null;
              }

              if (viewpoint_topic_picture?.filename_disk) {
                topicImageMap[viewpoint_topic_picture.filename_disk] = true;
              }

              const { viewpoint_topic_header, languages_code } = translation;

              const result: Topic = {
                id,
                pageId: id,
                languageCode: languages_code.code,
                topicImage: viewpoint_topic_picture?.filename_download,
                text: viewpoint_topic_header,
                presentationOrder: presentation_order,
              };
              return result;
            }
          )
          .filter((c) => !!c) as Topic[];

        const images: Array<TopicImage> = fileNodes
          .filter((c) => topicImageMap[c?.filename_disk] !== undefined)
          .map((c) => {
            const result: TopicImage = {
              childImageSharp: c.remoteImage.childImageSharp,
              name: c.remoteImage.name,
              ext: c.remoteImage.ext,
              fullName: `${c.remoteImage.name}${c.remoteImage.ext}`,
            };
            return result;
          });

        return render({
          topics: mappedTopics,
          viewpointTopics: mappedViewpointTopics,
          images,
        });
      }}
    />
  );
};
