import { graphql, StaticQuery } from "gatsby";
import React, { FC } from "react";
import { QuestionsAnswersProps } from ".";
import { CountryKey } from "../../../translations/countries";
import { useLanguageState } from "../../../utils/contexts/languageContext";
import { RenderCallback } from "../../../utils/types/misc";
import { isStatusAllowed } from "../../../utils/misc";
import { Answer, Question } from "./types";
import { LanguagesType } from "../languages/index";

export type QuestionsAnswersWithBackendProps = {
  render: RenderCallback<QuestionsAnswersProps>;
};

type DirectusQuestion = {
  id: string;
  status: string;
  presentation_order: number;
  question_topic: {
    id: string;
  };
  translations: [
    {
      question: string;
      languages_code: {
        code: string;
      };
    }
  ];
};

type DirectusQuestionAnswer = {
  id: string;
  status: string;
  presentation_order: number;
  a_country: string;
  b_country: string;
  related_question: {
    id: string;
  };
  translations: [
    {
      answer_html: string;
      languages_code: {
        code: string;
      };
    }
  ];
};

type DirectusQuestionTopic = {
  id: string;
  translations: [
    {
      country_a_header: string;
      country_b_header: string;
      languages_code: {
        code: string;
      };
    }
  ];
};

type DirectusData = {
  directus: {
    questions: Array<DirectusQuestion>;
    question_answers: Array<DirectusQuestionAnswer>;
    question_topics: Array<DirectusQuestionTopic>;
  };
};

export const QuestionsAnswersWithBackend: FC<QuestionsAnswersWithBackendProps> = ({
  render,
}) => {
  return (
    <StaticQuery
      query={graphql`
        {
          directus {
            questions(
              limit: -1
              filter: { status: { _in: ["published", "draft"] } }
            ) {
              id
              status
              presentation_order
              question_topic {
                id
              }
              translations {
                question
                languages_code {
                  code
                }
              }
            }
            question_answers(
              limit: -1
              filter: { status: { _in: ["published", "draft"] } }
            ) {
              id
              status
              presentation_order
              a_country
              b_country
              related_question {
                id
              }
              translations {
                answer_html
                languages_code {
                  code
                }
              }
            }
            question_topics(limit: -1) {
              id
              translations {
                languages_code {
                  code
                }
                country_a_header
                country_b_header
              }
            }
          }
        }
      `}
      render={({
        directus: { questions, question_answers, question_topics },
      }: DirectusData) => {
        const { languageCode: currentLanguage } = useLanguageState();
        const mappedQuestions = questions
          .filter((c) => isStatusAllowed(c.status))
          .map(
            ({
              id,
              presentation_order,
              question_topic: { id: topicId },
              translations,
            }) => {
              const questionTranslation = translations.find(
                (t: { languages_code: { code: string } }) =>
                  t.languages_code?.code == currentLanguage
              );

              if (!questionTranslation) {
                return null;
              }

              const topic = question_topics.find((c) => c.id === topicId);
              const topicTranslation = topic?.translations?.find(
                (c) => c.languages_code?.code === currentLanguage
              );
              const question: Question = {
                id: id,
                presentationOrder: presentation_order,
                question: questionTranslation?.question ?? "",
                languageCode: questionTranslation?.languages_code?.code ?? "",
                topic: {
                  id: topicId,
                  sourceCountryHeader: topicTranslation?.country_a_header ?? "",
                  targetCountryHeader: topicTranslation?.country_b_header ?? "",
                },
              };
              return question;
            }
          )
          .filter((c) => !!c);

        const mappedAnswers = question_answers
          .filter((c) => isStatusAllowed(c.status))
          .map(
            ({
              id,
              presentation_order,
              translations,
              a_country,
              b_country,
              related_question,
            }) => {
              const answerTranslation = translations.find(
                (t: { languages_code: { code: string } }) =>
                  t.languages_code?.code == currentLanguage
              );

              if (!answerTranslation) {
                return null;
              }

              const answer: Answer = {
                id: id,
                presentationOrder: presentation_order,
                relatedQuestionId: related_question.id,
                sourceCountry: a_country as CountryKey,
                targetCountry: b_country as CountryKey,
                languageCode: answerTranslation?.languages_code?.code ?? "",
                answerHtml: answerTranslation?.answer_html ?? "",
              };
              return answer;
            }
          )
          .filter((c) => !!c);

        return render({
          questions: mappedQuestions as Question[],
          answers: mappedAnswers as Answer[],
        });
      }}
    />
  );
};

type WithLanguageFilteringProps = {
  languages: LanguagesType;
};

export const WithLanguageFiltering: FC<WithLanguageFilteringProps> = ({
  children,
  languages,
}) => {
  const { languageCode } = useLanguageState();
  if (languages.find(({ code, hasQa }) => code === languageCode && hasQa)) {
    return <>{children}</>;
  } else {
    return (
      <p>
        The FAQ:s are not available in English. Please choose a Nordic language
      </p>
    );
  }
};
