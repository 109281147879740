import React, { FC, useState, useEffect } from "react";
import { CountryKey } from "../../../translations/countries";
import parse from "html-react-parser";
import styled from "styled-components";
import { createHtmlParserOptions } from "../../../utils/htmlParser";
import { Separator } from "reakit";
import {
  colorThemes,
  rhythm,
  mediaBreakpoints,
} from "../../../utils/typography";
import { SelectCountryMenu } from "../selectCountry/selectCountryMenu";
import { useLanguageState } from "../../../utils/contexts/languageContext";
import { getCountries } from "../selectCountry/countries";
import { TranslationKey } from "../../../translations/languages";
import Label from "../../atoms/label";
import { getTranslation } from "../../../translations/questionsAnswers";
import { compareByPresentationOrder } from "../../../utils/misc";
import { Question, Answer as AnswerType } from "./types";

const { md } = mediaBreakpoints;
const {
  separator: { primary: separatorPrimary },
} = colorThemes;

const AnswersContainer = styled.div`
  display: flex;
  flex-flow: column wrap;
  align-items: flex-start;
  height: 100%;
  width: 100%;
`;

const StyledSeparator = styled(Separator)`
  border: 0px;
  border-top: 2px dashed ${separatorPrimary};
  background: transparent;
  width: 100%;
`;

const SelectCountriesContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  margin-top: ${rhythm(1)};
  margin-bottom: ${rhythm(1.5)};
  & > * {
    margin-top: ${rhythm(1)};
  }
  @media only screen and (min-width: ${md}) {
    margin-top: ${rhythm(0.5)};
    & > * {
      margin-right: ${rhythm(2)};
      margin-top: ${rhythm(1)};
    }
  }
`;

const VerticalContainer = styled.div`
  display: flex;
  flex-flow: column wrap;
`;

type AnswerProps = {
  rawHtml: string;
  languageCode: string;
};

const Answer: FC<AnswerProps> = ({ rawHtml, languageCode }) => {
  const options = createHtmlParserOptions(languageCode);
  const parsedHtml = parse(rawHtml, options);
  return <>{parsedHtml}</>;
};

type AnswersProps = {
  question: Question;
  questionAnswers: Array<AnswerType>;
};

export const Answers: FC<AnswersProps> = ({
  question,
  questionAnswers,
  ...rest
}) => {
  const { languageCode } = useLanguageState();

  const selectCountryText = getTranslation(
    languageCode as TranslationKey,
    "selectCountry"
  );

  const countries = getCountries(languageCode as TranslationKey);
  const [sourceCountries, setSourceCountries] = useState(countries);
  const [targetCountries, setTargetCountries] = useState(countries);

  useEffect(() => {
    const countries = getCountries(languageCode as TranslationKey);
    setSourceCountries(countries);
    setTargetCountries(getCountries(languageCode as TranslationKey));
  }, [languageCode]);

  const [sourceCountry, setSourceCountry] = useState<CountryKey | null>(null);
  const [targetCountry, setTargetCountry] = useState<CountryKey | null>(null);

  const filteredQuestionAnswers = questionAnswers.filter(
    (answer) =>
      answer.sourceCountry === sourceCountry &&
      answer.targetCountry === targetCountry
  );
  
  return (
    <AnswersContainer {...rest}>
      <SelectCountriesContainer>
        <VerticalContainer>
          <Label htmlFor="sourceCountryCountry">
            {question.topic.sourceCountryHeader}
          </Label>
          <SelectCountryMenu
            id="sourceCountryCountry"
            initialPlaceHolder={selectCountryText}
            countries={sourceCountries}
            onCountrySelected={(c) => setSourceCountry(c)}
            selectedItemId={sourceCountry as string}
            menuAriaLabel={`${question.topic.sourceCountryHeader} ${selectCountryText}`}
            minwidth={"15rem"}
          />
        </VerticalContainer>
        <VerticalContainer>
          <Label htmlFor="targetCountry">
            {question.topic.targetCountryHeader}
          </Label>
          <SelectCountryMenu
            id="targetCountry"
            initialPlaceHolder={selectCountryText}
            countries={targetCountries}
            onCountrySelected={(c) => setTargetCountry(c)}
            selectedItemId={targetCountry as string}
            menuAriaLabel={`${question.topic.targetCountryHeader} ${selectCountryText}`}
            minwidth={"15rem"}
          />
        </VerticalContainer>
      </SelectCountriesContainer>
      {filteredQuestionAnswers
        .sort(compareByPresentationOrder)
        .map((answer, i) => (
          <React.Fragment key={answer.id}>
            <Answer
              languageCode={answer.languageCode}
              rawHtml={answer.answerHtml}
            />
            {i < filteredQuestionAnswers.length - 1 && <StyledSeparator />}
          </React.Fragment>
        ))}
    </AnswersContainer>
  );
};
